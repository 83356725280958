<template>
  <div class="wrap-content">
    <div class="header">
      <div class="return">
        <div class="return-icon" @click="handleBack">
          <Icon type="ios-arrow-back" />
        </div>
        <span>数据目录填报</span>
        <span> / 上传报送文件</span>
      </div>
      <ul></ul>
      <div class="button">
        <CommonHeader />
      </div>
    </div>
    <div class="main">
      <div class="card">
        <h2>上传重要核心数据目录清单盖章文件</h2>
        <div class="upload-box" v-if="!modal">
          <Upload
            multiple
            type="drag"
            :action="action"
            ref="upload"
            :max-size="10240"
            accept="pdf"
            :format="format"
            :headers="headers"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :on-error="handleError"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
          >
            <div class="upload-content">
              <img
                src="../../assets/img/点击上传-默认.png"
                alt=""
                class="upload-logo"
              />
              <h2>点击上传</h2>
              <p>或者拖拽本地文件到此区域</p>
              <p class="orange">支持扩展名：pdf</p>
            </div>
          </Upload>
        </div>

        <div class="file-box" v-if="modal">
          <div class="file-box-header">
            <div class="logo">
              <img src="../../assets/img/pdf.png" alt />
            </div>
            <div class="file-info">
              <p class="fileName" v-if="fileInfo.name">{{ fileInfo.name }}</p>
              <p class="dataSize" v-if="fileInfo.size">{{ fileInfo.size }}</p>
            </div>
          </div>
          <div class="action-box">
            <div class="btn btn-simple margin-right-10" @click="handleAgain">
              重新选择
            </div>
            <div class="btn btn-default" @click="handleConfirm">确定</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
  
  <script setup>
import CommonHeader from "@/components/commonHeader.vue";
import Footer from "@/components/footer.vue";
import { Modal, Message } from "view-ui-plus";
import { useRouter } from "vue-router";
import { reactive, ref, onMounted, inject } from "vue";
let router = useRouter();
let isHover = ref(false);
let axios = inject("axios");
const handleBack = () => {
  router.back();
};

let action = process.env.VUE_APP_URL + `/content/import`;
let format = ["pdf"];
let headers = ref({
  Authorization: "",
});

let fileInfo = ref({});
let modal = ref(false);
//上传成功
const handleSuccess = (res, file) => {
  fileInfo.value = {
    ...file,
    size: formatBytes(file.size),
  };
  modal.value = true;
};
//上传失败
const handleError = (res, file) => {
  // console.log(res, file);
  Message.error({
    background: true,
    content: res.data.message,
    duration: 3,
  });
};
//上传格式错误
const handleFormatError = (file) => {
  Message.error({
    background: true,
    content: "文件" + file.name + " 格式错误,请选择pdf格式文件",
    duration: 5,
  });
};
//超过上传大小
const handleMaxSize = (file) => {
  Message.error({
    background: true,
    content: "文件" + file.name + " 太大，不要超过10M.",
    duration: 5,
  });
};

//字节单位转换
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 B"; //Bytes
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

//获取用户信息
const getUserInfo = () => {
  if (localStorage.getItem("User")) {
    let user = JSON.parse(localStorage.getItem("User"));
    if (user != null && user != undefined) {
      headers.value.Authorization = "Bearer " + user.auth_token;
    }
  }
};
//重新选择
const handleAgain = () => {
  fileInfo.value = null;
  modal.value = false;
};
// 确认
const handleConfirm = () => {
  let url = ``;
};
onMounted(() => {
  getUserInfo();
});
</script>
  
  <style lang="scss" scoped>
.wrap-content {
  padding: 0 40px;
  background: #fff;
  min-height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 15px 0;
  height: 70px;
  > h2 {
    font-family: PingFangSC-Medium;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #111111;
  }
  ul {
    display: flex;
    li {
      cursor: pointer;
      color: #888888;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      height: 40px;
      width: 150px;
      letter-spacing: 0px;
      text-align: center;
      color: #111111;
    }
    li.active {
      background: #f6f6f6;
      border-radius: 10px;
      color: #111111;
      font-weight: bold;
    }
  }
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 20px;
  }
  .btn-add {
    color: #fff;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}
.custom-btn {
  border: 1px solid #606060;
  color: #606060;
  border-radius: 6px;
  padding: 3px 20px;
  font-size: 14px;
}
.main {
  min-height: calc(100vh - 110px);
  display: flex;
  justify-content: center;
  align-content: center;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    color: #111111;
    // line-height: 33px;
    margin-bottom: 60px;
    text-align: center;
  }

  > p {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #555555;
    text-align: center;
    margin-top: 30px;
    .link {
      color: #3d55a8;
    }
    .link:hover {
      cursor: pointer;
      //   text-decoration: underline;
    }
  }
  > .btn {
    width: 180px;
    height: 30px;
    margin: 30px auto 0;
  }
}
.upload-box {
  :deep(.ivu-upload-drag) {
    border-radius: 20px;
    border: dashed 2px #ff8503;
    width: 800px;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.upload-content {
  //   padding: 110px 0;
  .upload-logo {
    display: inline-block;
    width: 28px;
    height: 35px;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    color: #111111;
    margin: 20px 0;
    line-height: 36px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    color: #555555;
    line-height: 30px;
    margin-top: 20px;
  }
  .orange {
    color: #ffad59;
    line-height: 30px;
  }
}

.file-box {
  border-radius: 20px;
  border: solid 2px #c4cce5;
  padding: 30px;
  .file-box-header {
    padding: 15px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(#eaeffd, #eaeffd),
      linear-gradient(#2c399d, #2c399d);
    background-blend-mode: normal, normal;
    border-bottom: solid 1px #dddddd;
    .logo {
      width: 50px;
      height: 50px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#2c399d, #2c399d);
      background-blend-mode: normal, normal;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      img {
        display: block;
        width: 24px;
        height: 30px;
      }
    }
    .file-info {
      .fileName {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 33px;
        color: #111111;
      }
      .dataSize {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 33px;
        color: #555555;
      }
    }
  }
}
.action-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  .btn {
    width: 120px;
    height: 40px;
  }
}
</style>